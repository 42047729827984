import React, {useEffect, useRef, useState} from "react";
import clsx from "clsx";
import "./reveal.css";

interface RevealProps {
    children: React.ReactNode | React.ReactNode[];
    className?: string;
    direction?: "left" | "right" | "top" | "bottom";
}

export default function RevealDiv(props: RevealProps) {
    const [revealed, setRevealed] = useState(false);
    const ref = useRef(null);
    const direction = props.direction ?? "left";

    useEffect(() => {
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if(entry.intersectionRatio > .1) {
                    setRevealed(true);
                    if(ref.current) {
                        observer.unobserve(ref.current);
                    }
                }
            })
        }, {
            root: null,
            rootMargin: "0px",
            threshold: .1
        });

        if(ref.current) {
            observer.observe(ref.current);
        }
    }, []);

    return (
        <div className={clsx(props.className, "reveal-base", revealed ? "revealed" : `reveal-${direction}`)} ref={ref}>
            {props.children}
        </div>
    );
}