import {useNavigate, useParams} from "react-router-dom";
import {useArticle, useEditArticle} from "../../../api/articleApi";
import Loading from "../../../widgets/loading/loading";
import React from "react";
import {Delta} from "quill";
import ArticleTopBar from "../article-top-bar/article-top-bar";
import TextEditor from "../../../widgets/text-editor/text-editor";
import ArticlePublish from "../article-publish/article-publish";
import {Article} from "../../../model/article";

export default function ArticleEdit() {
    const params = useParams();
    const { data: article, isLoading, isError, isSuccess } = useArticle(parseInt(params.id ?? ""));

    return (
        <>
            {isLoading && <Loading className="force-margin-center" />}
            {isError && <div className={"text-alert"}>Error</div>}
            {isSuccess && <ArticleEditPage article={article.data}/>}
        </>
    );
}

interface ArticleEditPageProps {
    article: Article;
}

function ArticleEditPage({ article }: ArticleEditPageProps) {
    const [text, setText] = React.useState<Delta | null>(JSON.parse(article.content));
    const [thumbnail, setThumbnail] = React.useState<File | null>(null);
    const [title, setTitle] = React.useState<string>(article.title);
    const [description, setDescription] = React.useState<string>(article.description);
    const [visible, setVisible] = React.useState<boolean>(article.visible);
    const navigate = useNavigate();

    const editArticle = useEditArticle({
        onSuccess: (response: any) => {
            navigate(`/article/${article.id}`);
        },
        onError: (error: any) => {
            alert(error);
        },
    });

    return (
        <div className={"flex flex-col max-h-screen h-full w-full overflow-y-hidden p-1"}>
            <ArticleTopBar
                className={"md:max-h-46 md:h-46"}
                title={title}
                onTitleChange={setTitle}
                thumbnail={thumbnail}
                onThumbnailChange={setThumbnail}
                description={description}
                onDescriptionChange={setDescription}
                visible={visible}
                onVisibleChange={setVisible} />
            <TextEditor
                className={"flex-grow overflow-y-hidden p-2 h-full"}
                value={text}
                onChange={setText} />
            <ArticlePublish onPublish={() => editArticle.mutate({
                id: article.id,
                content: JSON.stringify(text),
                title,
                thumbnail: thumbnail ?? undefined,
                description,
                publishDate: new Date(),
                visible
            })} className={"h-1/12 p-4"} />
        </div>
    );
}

// {isSuccess && <ArticleDisplay article={article.data}/>}