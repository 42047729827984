import Helmet from "react-helmet";
import React from "react";

export interface MetaTagsProps {
    title: string;
    description: string;
    url: string;
    image: string;
}

export function MetaTags(props: MetaTagsProps) {

    return (
        <Helmet>

            <title>{props.title}</title>
            <meta name="description" content={props.description}/>

            <meta itemProp="name" content={props.title}/>
            <meta itemProp="description" content={props.description}/>
            <meta itemProp="image" content={props.image}/>

            <meta property="og:url" content={props.url}/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content={props.title}/>
            <meta property="og:description" content={props.description}/>
            <meta property="og:image" content={props.image}/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={props.title}/>
            <meta name="twitter:description" content={props.description}/>
            <meta name="twitter:image" content={props.image}/>
        </Helmet>
    );
}