import React, { useState } from 'react';
import clsx from "clsx";
import "./tabs.css";

interface TabProps {
    name: string;
    tabRenderer?: () => JSX.Element;
    isActive: boolean;
    onClick: () => void;
}

function Tab({ name, tabRenderer, isActive, onClick }: TabProps): JSX.Element {
    const tab = tabRenderer ? tabRenderer() : <div>{name}</div>;

    return (
      <button
          onClick={onClick}
          className={clsx("flex flex-row mr-8 font-size-2xl underlined-tab after:bg-main-text", isActive && "opacity-100 tab-enabled", !isActive && "opacity-60 hover:opacity-100")}>
          {tab}
      </button>
    );
}

interface TabData {
    name: string;
    tabRenderer?: () => JSX.Element;
    tabContent: React.ReactNode | React.ReactNode[];
}

interface TabsGroupProps {
    tabs: TabData[];
    className?: string;
    defaultTab?: string;
}

export default function TabsGroup({ tabs, className, defaultTab }: TabsGroupProps) {
    const [active, setActive] = useState(defaultTab ? tabs.find((t) => t.name === defaultTab) : (tabs[0] ?? null));

    return (
        <>
            <div className={clsx("flex flex-wrap flex-col md:flex-row", className)}>
                {tabs.map(t => (
                    <Tab
                        key={t.name}
                        name={t.name}
                        isActive={t.name === active?.name}
                        tabRenderer={t.tabRenderer}
                        onClick={() => setActive(t)}
                    />
                ))}
            </div>
            {active && active.tabContent}
        </>
    );
}
