import React, {useEffect} from "react";
import hljs from "../../hljs/hljs";
import {Delta, Sources} from "quill";
import ReactQuill from "react-quill";
import QuillEditor from "quill";
import {uploadArticleImage} from "../../api/articleApi";
import {apiConfig} from "../../api/apiConfig";
import clsx from "clsx";
import "./text-editor.css";

interface TextEditorProps {
    onChange: (value: Delta | null) => void;
    value: Delta | null;
    className: string;
}

export default function TextEditor(props: TextEditorProps) {
    const [text, setText] = React.useState<Delta | null>(props.value);
    const [uploading, setUploading] = React.useState(false);
    const [isPending, startTransition] = React.useTransition();
    let quillRef = React.useRef<ReactQuill & { editor: QuillEditor }>(null);

    useEffect(() => {
        quillRef.current?.editor.root.setAttribute("spellcheck", "false");
    }, [quillRef]);

    const onChangeContent = (content: string, delta: Delta, source: Sources, editor: any) => {
        setText(editor.getContents());
        startTransition(() => {
            props.onChange(editor.getContents());
        });
    }

    const imageHandler = () => {
        const fileInput = document.createElement("input");

        fileInput.type = "file";
        fileInput.accept = "image/*";
        fileInput.click();

        fileInput.onchange = async (e: Event) => {
            const file = fileInput.files?.[0];

            if (file) {
                const editor = quillRef?.current?.getEditor();
                const index = editor?.getSelection(true)?.index ?? 0;

                editor?.setSelection(index, 1);

                uploadArticleImage(file)
                    .then(res => {
                        const url = `${apiConfig.url}/articles/images/${res.data}`;
                        editor?.deleteText(index, 1);
                        editor?.insertEmbed(index, "image", url);
                        setUploading(false);
                        // @ts-ignore
                        const content = editor?.editor?.getContents(0, editor?.getLength());

                        if (content) {
                            setText(content);
                            startTransition(() => {
                                props.onChange(content);
                            });
                        }
                    });
            }
        }
    };

    const modules = React.useMemo(() => ({
        toolbar: {
            handlers: {
                'image': () => {
                    imageHandler();
                }
            },
            container: [
                [{ "font": [] }],
                [{ "size": ["small", false, "large", "huge"] }],
                ["bold", "italic", "underline"],
                [{ "list": "ordered" }, { "list": "bullet" }],
                [{ "align": [] }],
                [{ "color": [] }, { "background": [] }],
                ["clean"],
                ["link", "image", "video"],
                ["code-block", "blockquote"],
            ],
        },
        syntax: {
            highlight: (text: string, language: string) => {
                if (language) {
                    return hljs.highlight(language, text).value;
                } else {
                    return hljs.highlightAuto(text).value;
                }
            }
        },
    }), []);

    const formats = [
        "font",
        "size",
        "bold", "italic", "underline",
        "list", "bullet",
        "align",
        "image",
        "video",
        "link",
        "color', 'background",
        "code-block",
        "blockquote",
    ];

    return (
        <div className={clsx("w-full", props.className)}>
            <ReactQuill
                placeholder={"Write something..."}
                ref={quillRef}
                theme={"snow"}
                modules={modules}
                formats={formats}
                onChange={onChangeContent}
                className={"quill-editor border-0 border-transparent  overflow-y-auto max-h-full h-full"}
                defaultValue={text ?? ""}
                //onBlur={handleOnBlur}
            />
        </div>
    );
}
