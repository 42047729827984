import {Delta, Sources} from "quill";

const DRAFT_ENTRY = "text_draft_data";

export function saveDraft(delta: Delta | null) {
    if(delta) {
        localStorage.setItem(DRAFT_ENTRY, JSON.stringify(delta));
    }
    else {
        localStorage.removeItem(DRAFT_ENTRY);
    }
}

export function loadDraft(): Delta | null {
    const draft = localStorage.getItem(DRAFT_ENTRY);
    if (draft) {
        return JSON.parse(draft);
    }

    return null;
}