import TextInput from "../text-input/text-input";
import {useState} from "react";
import useAuthentication from "../../hooks/authenticator";
import FrameButton from "../button/frame-button";
import BlinkingButton from "../blinking/blinking-button";
import {faPassport} from "@fortawesome/free-solid-svg-icons";

interface LoginFormProps {
    onLogged: () => void;
}

export default function LoginForm(props: LoginFormProps) {
    const [login, setLogin] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const { isLoggedIn, logIn } = useAuthentication();

    const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        logIn?.(login, password);
    };

    if (isLoggedIn)
        props.onLogged();

    return (
        <form className={"flex flex-col"} onSubmit={handleLogin}>
            <TextInput name={"Login"} value={login} onChange={(event) => setLogin(event.target.value)} />
            <TextInput name={"Password"} value={password} onChange={(event) => setPassword(event.target.value)} type={"password"} />
            <BlinkingButton icon={faPassport} className={"mt-4 text-center ml-auto"} type={"submit"}>Login</BlinkingButton>
        </form>
    )
}