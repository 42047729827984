import {useEffect, useRef} from "react";

export default function useKey(key: string, onPress: (event: KeyboardEvent) => void) {
    const callback = useRef(onPress);

    useEffect(() => {
        callback.current = onPress;
    }, [onPress]);


    useEffect(() => {
        function handle(event: KeyboardEvent) {
            if(event.code === key){
                callback.current(event);
            } else if (key === 'ctrls' && event.key === 's' && event.ctrlKey) {
                callback.current(event);
            }
        }

        document.addEventListener('keydown',handle);
        return () => document.removeEventListener("keydown",handle);
    },[key])
}