import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface IconButtonData {
    icon: IconDefinition;
    onClick: () => void;
    label: string;
}

export default function IconButton(props: IconButtonData) {
    return (<button className="hover:text-accent mx-4" type={"button"} onClick={props.onClick}>
        <p className="sociallnk-hover:text-accent text-center">{props.label}</p>
        <span className="sociallnk-hover:text-accent color-main text-7xl">
            <FontAwesomeIcon icon={props.icon}/>
        </span>
    </button>);
}