import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAt, faCalendarAlt, faClipboard, faFingerprint, faIdBadge} from "@fortawesome/free-solid-svg-icons";
import BlinkingButton from "../blinking/blinking-button";

export default function MyPGP() {
    const copyToClipBoard = () => {
        const pgpPublic = document.getElementById("pgp-public");

        if(pgpPublic) {
            navigator.clipboard.writeText(pgpPublic.innerText);
        }
    };

    return <div className="h-1/12">
        <div className="flex flex-col w-full">
            <span><FontAwesomeIcon icon={faFingerprint}/><span className="ml-4">2102 FCF1 1333 13C8 4E34  06A4 CE3D DAB6 CF04 F0A2</span></span>
            <span><FontAwesomeIcon icon={faIdBadge}/><span className="ml-4">0xCF04F0A2</span></span>
            <span><FontAwesomeIcon icon={faCalendarAlt}/><span className="ml-4">20/12/2021 - 20/12/2023</span></span>
            <span><FontAwesomeIcon icon={faAt}/><a className="ml-4" href="mailto:admin@bedevin.com">admin@bedevin.com</a></span>
            <span className="my-4"></span>
            <BlinkingButton className="mb-2 ml-auto" onClick={copyToClipBoard} icon={faClipboard}>
                Copy to clipboard
            </BlinkingButton>
            <code id="pgp-public" className="resize-none bg-dark-background text-main-text overflow-y-auto max-h-64 w-pgp">
                -----BEGIN PGP PUBLIC KEY BLOCK-----<br/><br/>

                mQINBGHAe3MBEADMOechzvQFS7q8CFkftjKui3VxZrhWvqmLCTfXhuaQO3VGOkhV<br/>
                qIzn8jcyLXNBpvyoG2zoJutzhXFRo4jeYzp1dyWJBtmIh9Lrsa8l1NXNQQ9TyqTx<br/>
                mryOa048sOzyTi3FkG6XGNjjoqhU85vAnWGkTsZMgj/Yo+UOqwRgeSh/Rf/pErLb<br/>
                +QZxaZEn7K5Ma78l8VpsPqc2iDVYfcMm0+DeTTf4ksiobVihpNBX2bBEg838soeN<br/>
                Ie9/H2ORx6j+1JACezgosS6TrJAaM/qdK0frBQa5VaYYrKhgQSiiqIVmMPbYZnUI<br/>
                qiIMGViJqPCCGmr0u6WR8PJn6T+1darWjHdCVvLsynw5hg1U110rhWgpeHP/+d4g<br/>
                eRINjPPHo6i0OUoc6RwK+iQUjfSauIhTBn/hdduhFMbBaTtSFBc/eNA9XwiQTnf/<br/>
                J9IZPJf30/uPaMCO0UWcUdhBsFQsuLj3A5CCvpJOgqDtI6laiZD5QHnmR5CibLM4<br/>
                qZiN8jb/jSCB49jS7gfSRrd/ZX1S2MPfHjTBzt5G6DDQhZpKIRd9T4o7GfgiClD+<br/>
                MgnVzb3jmip4WzJsp7DHQefCzcuv6rVmFZEfCc9FGtGxRXcfVY68rKR3tS1qmvUc<br/>
                aYRnIZVJy8evc7J3aR+LHrGjFAmeYctymBw6sIyqOI50wE6Hw79PxFn2WQARAQAB<br/>
                tBpCZW5qYW0gPGFkbWluQGJlZGV2aW4uY29tPokCVAQTAQgAPhYhBCEC/PETMxPI<br/>
                TjQGpM492rbPBPCiBQJhwHtzAhsDBQkDwk29BQsJCAcCBhUKCQgLAgQWAgMBAh4B<br/>
                AheAAAoJEM492rbPBPCilzMQAKdj9f1pB9MREnDNiL1ERp5Uk5r2NSQ+ulSzu8ph<br/>
                nylo09zrdh143WGzjReocDQbFBGxCIZGnZg8+YWixJ52x+m6FlngCnhx3P96ylUp<br/>
                JLtQdChLaaHTChilz78HmClj+LnDgLKosJePNhTJhBJelURzh7t9Ciyl6w/26hhH<br/>
                kZoMP3ykngmKFj3VWCERZw53da1h9O6tqfa12UDdCbscE0vjU70EK12ostT9bMKW<br/>
                eediwl3kjQzV5MO844pN9Vm9Jbf8Eva90rV+DKx+Cn9riGjQxGnfnkSjd2U4HMKc<br/>
                Lf6WpB8hYKTckF/F1mIQ8t1fpLbLjB5U7J8iMBYjVcZc9fgG6dJ8zF6NHbcHepeg<br/>
                9BAcs2pOkqvgmOVaI+tccW4rOaIq+HZT7bL9YkqU1vO5zMUlLm6KrFPMtgcONotT<br/>
                XxbujMHN+XFFYZEOmUkHTDJrVwYw32voK91PZyN5Ipm3K5akIQ9Krm7k21X7fLdc<br/>
                0PkCj9ona1oy5S8zFW7RlAOwmNlkD5fztDFdU/c0GCrVev0NWgu3mdQuIDpu+WEF<br/>
                +Gb24n05ikg1iXbHhj+b9FQgt1MlkCWHY8vAohdlnk+wYpt1Ay/fzh042HPpamv4<br/>
                Fzvy3nx8cXfU95iyO4RMpiXD8SWHyGsJNSygdITyt4/6KZDbAB/tA+8xn2jOOYT/<br/>
                gz3KuQINBGHAe3MBEADPH4MtwuR+XLTmAmaqeBzm6qfRxiy4TnkZAQDQkAhrMJlh<br/>
                Cc/oHXtVFu8vhYQ6cF8HM+vm9CODQGkVuCbIdXvjoZ09PSkDK4Ekt7IUMfNduoj3<br/>
                fJprtr374BwUZDM0pXW4ng6jYPZHp1Ns6O7mnwGNYRJ5SwxlSrcCY9lL4FILRlTx<br/>
                iVFyYauUcAv/df0PR9uLfltUhoWTuJw5BzjAQZi3M/EHx8gDJ7+OLddFgRYGuPeB<br/>
                6FmPPcmg2okJTtSXF9GqyIhnIAEwGRbdO6SjF4z3zUUEpWpXVCfsiP9TzYQNLER6<br/>
                M3ZeH6vpU7FXNk5Em7O0oWwXb4U+aaRogGVL2Xg3S8dEOy688I1nddCgXCgBhQaJ<br/>
                QhlqhcTPlL9Lvp4MaW4ZDoI5oqSMdPkneSxZ2M24J5LqnAqX51XVVxVVd9wEaieE<br/>
                phnGSj+O8mk1cSvl0gM+GUTzDWtEwvdjThdWwIMqJ+vB1xILZHzx/LO9ztONBwFX<br/>
                AgMQg5ByLy/UmPtqix/l5sHzWfPgFNt3mwhOIK/GbE6CCzKrl6WKzCJg1h78eVvS<br/>
                rvMQYFlFXkb6XXglO5CU4mXdmfHZbUtmAy68S1gTUbXMoGn4MPAOIeOgwIAPgOl9<br/>
                2h1oWGzb1i1nw0tQhlDog46k0lHBRZpThZ8fTYryZ1yqzmawB5Nbgov7wYjN2QAR<br/>
                AQABiQI8BBgBCAAmFiEEIQL88RMzE8hONAakzj3ats8E8KIFAmHAe3MCGwwFCQPC<br/>
                Tb0ACgkQzj3ats8E8KJhrBAAq4uHQuVWIlhvdxEW2l4I+3A2Qd7Co0wJ9WV+WwCC<br/>
                AOB8F3K3FzdVPfNTsovOL6wHNUO5h3FG1/YNmN6lYt8owCxdKYJ6T+TXw20fYIIf<br/>
                FwWhqZW1iONtq6lGpwZN9ePwRX2nHzI8C7OA0JM3tV1qpCLzXoUlsB816Qk5UHYj<br/>
                VD25NmxLCoyn9NQpc8RdTazMZsNU5Bps1j+g1jzLlHlp+KpOhLIMTLyf4f+CE6Zh<br/>
                2ur7EvSuckaMaPZ3iAHe0x3D08GJgFjyfejon1TaxG+jUByorEYhhHk0LXxJIFNT<br/>
                TDSgtxC/INLcC9VZm4RtEPuT35c/9csTJhFe70cGWV1KCRja/I7NgZIC/aB02e3S<br/>
                rRU1yc0NnC7nrFrq0Qm29XWnKyaJFiKAwCDYOxc+L5+6zq4ludZMKUm/3jVGKtjx<br/>
                lGQoT7VGp32QINBHW3OorVxg2Zv7XQOp/qpx2tbZrarlObrGU5Ok42+Lkv9NOlHS<br/>
                nlM7f1/wvy9HwvAs3HhzfH6VoAZH0Xdq+PnHOf9lulVF2L0OtZJwQESqV9ICTGZ5<br/>
                4aX+bsO5PYRP/CZflVeBV0olHXd93zBM0meDbntueoHr348ZcGOs+H9c3gn5+JIN<br/>
                hoX2xjSnH3S0/KBmwup7u1dmC/jviI+SQO7Bsjmpkw/spmDRQjyWFf39ntLy6ixI<br/>
                db8=<br/>
                =32+a<br/><br/>
                -----END PGP PUBLIC KEY BLOCK-----
            </code>
        </div>
    </div>;
}