import hljs from "highlight.js";
import "./hljs.css";
//import "highlight.js/styles/atom-one-dark.css";
//import "highlight.js/styles/atom-one-light.css";

hljs.configure({
    languages: ["javascript", "c", "c++", "rust", "c#", "java", "python", "php", "html", "typescript", "css", "scss", "swift", "kotlin", "go", "ruby", "lua", "r"],
});

(window as any).hljs = hljs;

export default hljs;