import ArticleMini from "../../widgets/article-mini/article-mini";
import {useAllArticles} from "../../api/articleApi";
import Loading from "../../widgets/loading/loading";
import {apiConfig} from "../../api/apiConfig";
import {Article} from "../../model/article";
import {toDdMmYyyy} from "../../helpers/dates";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBriefcase,
    faDiagramProject, faNewspaper,
    faSchool, faScrewdriverWrench,
    faWindowMaximize,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import ChangingText from "../../widgets/changing-text/changing-text";
import Timeline from "../../widgets/timeline/timeline";
import PlaceCard from "../../widgets/place-card/place-card";
import useWindowSize from "../../hooks/windowSize";
import RevealDiv from "../../widgets/reveal/reveal";
import "react-tabs/style/react-tabs.css";
import TabsGroup from "../../widgets/tabs/tabs";
import StarRating from "../../widgets/star-rating/star-rating";
import {useState} from "react";

function Tab({name, icon}: {name: string, icon: IconDefinition}) {
    return (
        <span className={"flex flex-row mr-auto"}>
            <FontAwesomeIcon className={"text-2xl md:text-3xl lg:text-4xl"} icon={icon}/>
            <h2 className={"text-2xl md:text-3xl lg:text-4xl ml-4 lg:ml-12"}>{name}</h2>
        </span>
    )
}

export default function Home() {
    return (
        <div className="flex flex-col min-h-screen overflow-y-auto overflow-y-auto p-4 lg:p-12 flex-grow">
            <Description />
            <TabsGroup
                className={"md:mx-auto md:items-center"}
                tabs={[
                {
                    tabRenderer: () => <Tab name={"Experience"} icon={faBriefcase} />,
                    tabContent: <Work />,
                    name: "Experience",
                },
                {
                    tabRenderer: () => <Tab name={"Education"} icon={faSchool} />,
                    tabContent: <School />,
                    name: "School",
                },
                {
                    tabRenderer: () => <Tab name={"Articles"} icon={faNewspaper} />,
                    tabContent: <AllArticles />,
                    name: "Projects",
                },
                {
                    tabRenderer: () => <Tab name={"Technologies"} icon={faScrewdriverWrench}/>,
                    tabContent: <Technologies />,
                    name: "Technologies",
                }
                ]}
                defaultTab={"Projects"}
            />

        </div>
    );
}

function Description() {
    return (
        <div className="w-full my-auto mb-8 max-w-full">
            <div className="flex flex-col intro-text text-2xl md:text-5xl">
                <span className={"text-3xl md:text-6xl lg:mb-8 font-bold"}>Benjamin Wirtz</span>
                <br/>
                <p>
                    A nice website to find
                    <br/>
                    <ChangingText texts={["a developer", "an analyst", "a counsellor", "a partner"]} idleTime={1000} typeTime={100} />
                    and much more
                </p>
                <hr className="my-4"/>
            </div>
        </div>
    );

    /*
    * <p className="text-2xl">
                    The trust you can't buy half a world away.
                </p>*/
}

function Work() {
    return (
        <div className={"flex flex-col w-full max-w-full flex flex-row min-h-144 z-20 mt-4"}>
            <Timeline
                className={"after:bg-light-main-text mx-auto overflow-x-hidden"}
                orientation={"vertical"}
                items={[
                    {
                        children: (<PlaceCard
                            date="June 2020 - September 2020"
                            text="Software engineer"
                            description="Development of Android applications (Java) and Unity (C#). Design of a test plan for a team of human testers. Customer relationship and product presentation."
                            link="https://www.sidema.be/fr/"
                            linkText="Sidema SPRL"
                            imageUrl={`${process.env.PUBLIC_URL}/images/sidema.png`}
                            />),
                        className: "bg-dark-background after:bg-dark-background",
                        circleClassName: "border-dark-background bg-main-background"
                    },
                    {
                        children: (<PlaceCard
                            date="February 2022 - May 2022"
                            text="Software engineer internship"
                            description="Development of a HR oriented React/ASP.NET web application. Study of an AI solution to automate the recruitment workflow of the company. Heavy testing and deployment via Docker containers."
                            link="https://www.evs.com/"
                            linkText="EVS Broadcast Equipment S.A."
                            imageUrl={`${process.env.PUBLIC_URL}/images/evs.png`}
                        />),
                        className: "bg-dark-background after:bg-dark-background",
                        circleClassName: "border-dark-background bg-main-background"
                    },
                    {
                        children: (<PlaceCard
                            date="June 2022 - September 2022"
                            text="Software engineer/DevOps"
                            description="Maintenance of WPF and ASP.NET applications. Study of Kubernetes and TeamCity solutions."
                            link="https://www.evs.com/"
                            linkText="EVS Broadcast Equipment S.A."
                            imageUrl={`${process.env.PUBLIC_URL}/images/evs.png`}
                        />),
                        className: "bg-dark-background after:bg-dark-background",
                        circleClassName: "border-dark-background bg-main-background"
                    },
                    {
                        children: (<PlaceCard
                            date="July 2023 - August 2023"
                            text="Software engineer/DevOps"
                            description="Integration of Ceph Rook dynamic kubernetes storage providement."
                            link="https://www.evs.com/"
                            linkText="EVS Broadcast Equipment S.A."
                            imageUrl={`${process.env.PUBLIC_URL}/images/evs.png`}
                        />),
                        className: "bg-dark-background after:bg-dark-background",
                        circleClassName: "border-dark-background bg-main-background"
                    },
                    {
                        children: (<PlaceCard
                            date="February 2024 - June 2024"
                            text="Artificial Intelligence Engineer"
                            description="Developement and training of a Deep Learning pitch lines detection model."
                            link="https://www.evs.com/"
                            linkText="EVS Broadcast Equipment S.A."
                            imageUrl={`${process.env.PUBLIC_URL}/images/evs.png`}
                        />),
                        className: "bg-dark-background after:bg-dark-background",
                        circleClassName: "border-dark-background bg-main-background"
                    },
                    {
                        children: (<PlaceCard
                            date="September 2024 - Present"
                            text="Sofware engineer/DevOps"
                            description="Maintenance of Kubernetes cluster and C# .NET development of a Kubernetes deployment solution."
                            link="https://www.evs.com/"
                            linkText="EVS Broadcast Equipment S.A."
                            imageUrl={`${process.env.PUBLIC_URL}/images/evs.png`}
                        />),
                        className: "bg-dark-background after:bg-dark-background",
                        circleClassName: "border-dark-background bg-main-background"
                    },
                    {
                        children: (<PlaceCard
                            date="September 2024 - Present"
                            text="CEO"
                            description="Development, management and selling AI and custom software business solutions."
                            link="https://www.quantraum.com/"
                            linkText="Quantraum"
                            imageUrl={`${process.env.PUBLIC_URL}/images/quantraum.png`}
                        />),
                        className: "bg-dark-background after:bg-dark-background",
                        circleClassName: "border-dark-background bg-main-background"
                    },
                ]} />
        </div>
    );
}

function School() {
    return (
        <div className={"flex flex-col w-full max-w-full flex flex-row min-h-144 mt-4"}>
            <Timeline
                className={"after:bg-light-main-text mx-auto overflow-x-auto"}
                orientation={"vertical"}
                items={[
                    {
                        children: (<PlaceCard
                            date="September 2011 - June 2017"
                            text="CESS - General - Science oriented"
                            description="I passed my CESS at Jury Central, self teaching all required classes. I learnt how to manage time and how to do things on my own."
                        />),
                        className: "bg-dark-background after:bg-dark-background",
                        circleClassName: "border-dark-background bg-main-background"
                    },
                    {
                        children: (<PlaceCard
                            date="September 2017 - June 2019"
                            text="Bachelor's Degree - Psychology and Education"
                            description="Learning theoretical psychological sciences basics. Interrupted at the end of my second year because I spent all my time programming."
                            link="https://www.programmes.uliege.be/cocoon/20212022/formations/bref/Y1PSYG01.html"
                            linkText="ULiège University"
                        />),
                        className: "bg-dark-background after:bg-dark-background",
                        circleClassName: "border-dark-background bg-main-background"
                    },
                    {
                        children: (<PlaceCard
                            date="September 2019 - June 2022"
                            text="Bachelor's Degree - Computer Sciences"
                            description="Learning programming principles, analysis in software architecture design. Magna cum laude without any remedial exams."
                            link="https://www.helmo.be/Formations/Economique/Bachelier-en-Informatique-de-gestion/Formation.aspx"
                            linkText="HELMo"
                        />),
                        className: "bg-dark-background after:bg-dark-background",
                        circleClassName: "border-dark-background bg-main-background"
                    },
                    {
                        children: (<PlaceCard
                            date="September 2022 - June 2024"
                            text="Master's Degree - Computer Sciences"
                            description="Learning advanced and current IT technologies such as Big Data, Cybersecurity, DevOps, IoT, Data Centers, Cloud Computing, ERP, Machine Learning, Virtual Reality, etc."
                            link="https://www.provincedeliege.be/fr/node/15649"
                            linkText="HEPL"
                        />),
                        className: "bg-dark-background after:bg-dark-background",
                        circleClassName: "border-dark-background bg-main-background"
                    },
                ]} />
        </div>
    );
}

function AllArticles() {
    const { data: articles, isLoading, isError, isSuccess } = useAllArticles();

    const articlesData = articles?.data ?? [];

    return (
        <div className={"w-full max-w-full flex flex-row flex-grow"}>
            <div className={"w-full flex flex-wrap flex-row justify-around text-sm"}>
                {isLoading && <Loading />}
                {isError && <div className={"text-alert"}>Error</div>}
                {isSuccess && !isLoading &&
                    articlesData?.length > 0 ?
                    articlesData.map((article: Article) =>
                        (<RevealDiv direction={"left"}>
                            <ArticleMini
                                title={article.title}
                                thumbnail={article.thumbnail ? `${apiConfig.url}/articles/images/${article.thumbnail}` : undefined}
                                content={article.description}
                                articleId={article.id}
                                published={toDdMmYyyy(new Date(article.publishDate))}
                                viewCount={article.viewCount ?? 0}/>
                        </RevealDiv>))
                    : <div className={"text-light-main-text my-auto text-5xl"}>No articles found</div>}
            </div>
        </div>
    );
}

function Technologies() {
    return (
        <div className={"w-full max-w-full flex flex-col flex-grow pt-8"}>
            <p className={"text-2xl"}>Programming</p>
            <div className={"w-full flex flex-col md:flex-row md:flex-wrap mb-4"}>
                <RevealDiv><Technology name={"C++"} logo={"./technologies/cpp.png"} rating={4.5} description={"I learnt C++ on my own, wrote several pieces of software including open source projects. C++ is my favourite language."} /></RevealDiv>
                <RevealDiv><Technology name={"C#"} logo={"./technologies/csharp.png"} rating={4.5} description={"I have an academic and professional experience with C#. I use ASP.NET, WPF/Avalonia or Blazor on an almost daily basis: this website is written in ASP.NET!"} /></RevealDiv>
                <RevealDiv><Technology name={"Javascript"} logo={"./technologies/javascript.png"} rating={4.5} description={"I have academic and professional experience with Javascript. I use it mainly for small web apps development."} /></RevealDiv>
                <RevealDiv><Technology name={"Java"} logo={"./technologies/java.png"} rating={4} description={"Java was the primary academic language at school. I mainly use Java for networking or Android development which was a part of my career."} /></RevealDiv>
                <RevealDiv><Technology name={"PHP"} logo={"./technologies/php.png"} rating={4} description={"PHP is a language that I infamously like because it works for one purpose: quickly writing a server's code."} /></RevealDiv>
                <RevealDiv><Technology name={"Typescript"} logo={"./technologies/typescript.png"} rating={4} description={"I am currently learning Typescript and somehow liking it. I actively use it for any new website project, including this website."} /></RevealDiv>
                <RevealDiv><Technology name={"Python"} logo={"./technologies/python.png"} rating={4} description={"I use Python for networking, system administration and installation scripts at work or at home."} /></RevealDiv>
                <RevealDiv><Technology name={"HTML5"} logo={"./technologies/html.png"} rating={4} description={"HTML is the base of the World Wide Web, I could be better in it, but I can always manage to achieve what I need."} /></RevealDiv>
                <RevealDiv><Technology name={"CSS3"} logo={"./technologies/css.png"} rating={3.5} description={"Not an expert of it, but I know how to center something, how to animate other things: I know how to write less of it."} /></RevealDiv>
                <RevealDiv><Technology name={"C"} logo={"./technologies/c.png"} rating={3.5} description={"I've used C for a long time but never in a professional or serious context. I've done systems programming on Linux and with Win32API."} /></RevealDiv>
                <RevealDiv><Technology name={"Pawn"} logo={"./technologies/pawn.png"} rating={4.5} description={"Pawn was my first ever programming language. I've written 300 000+ lines of code with it and completely pushed it to the limits."} /></RevealDiv>
            </div>
            <p className={"text-2xl"}>DevOps</p>
            <div className={"w-full flex flex-col md:flex-row md:flex-wrap"}>
                <RevealDiv><Technology name={"Docker"} logo={"./technologies/docker.png"} rating={4} description={"I heavily use Docker for simple deployment, load balancing through Kubernetes."} /></RevealDiv>
                <RevealDiv><Technology name={"Linux RHEL 8"} logo={"./technologies/rhel8.png"} rating={4} description={"I learnt RHEL8 Linux at school and it became my first choice for servers that I run. I know its main networking services such as DNS, DHCP, etc."} /></RevealDiv>
                <RevealDiv><Technology name={"Kubernetes"} logo={"./technologies/kubernetes.png"} rating={3} description={"I studied Kubernetes clustering solutions such as Rancher RKE or MicroK8s to run a large scale (100+ microservices) application."} /></RevealDiv>
                <RevealDiv><Technology name={"JetBrains TeamCity"} logo={"./technologies/teamcity.png"} rating={4.0} description={"I discovered TeamCity CI/CD at work where I configure large scale build chains."} /></RevealDiv>
            </div>
            <p className={"text-2xl"}>Libraries and APIs</p>
            <div className={"w-full flex flex-col md:flex-row md:flex-wrap"}>
                <RevealDiv><Technology name={"React"} logo={"./technologies/react.png"} rating={4.5} description={"React was the main web technology I have worked with. It is also the de facto web framework I use for all web projects including this website and many others."} /></RevealDiv>
                <RevealDiv><Technology name={"OpenGL"} logo={"./technologies/opengl.png"} rating={3.5} description={"I have used OpenGL3.3 in various contexts including the development of a 3D Engine. I have experience with several aspects such as shaders development, objects manipulation through VAO."} /></RevealDiv>
                <RevealDiv><Technology name={"Pytorch"} logo={"./technologies/pytorch.png"} rating={4.0} description={"Training and fine tuning of deep learning models."} /></RevealDiv>
                <RevealDiv><Technology name={"Next.JS"} logo={"./technologies/nextjs.png"} rating={3.5} description={"Web development or several websites."} /></RevealDiv>
            </div>
            <p className={"text-2xl"}>Tools</p>
            <div className={"w-full flex flex-col md:flex-row md:flex-wrap"}>
                <RevealDiv><Technology name={"JetBrains"} logo={"./technologies/jetbrains.png"} rating={4} description={"I have good knowledge and experience with JetBrains IDEs in general. I mainly use WebStorm, CLion, IntelliJ and PyCharm."} /></RevealDiv>
                <RevealDiv><Technology name={"Visual Studio"} logo={"./technologies/visualstudio.png"} rating={3.5} description={"I use Visual Studio for everything related to C#. I know most of its functionalities but I could not say that I am a master of it yet."} /></RevealDiv>
                <RevealDiv><Technology name={"Unity Engine"} logo={"./technologies/unity.png"} rating={3.5} description={"I've used Unity in both personal and professional contexts. I developed some games and apps with it."} /></RevealDiv>
            </div>
        </div>
    );
}

interface TechnologyProps {
    name: string;
    logo: string;
    rating: number;
    description: string;
}

function Technology(props: TechnologyProps) {
    return (
        <div className={"flex flex-row justify-between hover:bg-dark-background duration-200 p-4 h-full"}>
            <div className={"flex flex-col w-32 pb-4 h-full"}>
                <img src={props.logo} alt={`${props.name} logo`} className={"max-h-32 object-contain w-auto pr-2 mb-2"} />
                <StarRating rating={props.rating} disabled className={"mt-4 mx-auto mt-auto"} />
            </div>
            <div className={"flex flex-col w-48 pl-2 text-right"}>
                <span className={"font-bold mb-2"}>{props.name}</span>
                <hr className={"opacity-50"}/>
                <span className={"text-xs mt-2"}>{props.description}</span>
            </div>
        </div>
    );
}
