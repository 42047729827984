import React, { useState, useEffect } from 'react';
import classNames from "classnames";
import "./switch-button.css";
import clsx from "clsx";

interface SwitchButtonProps {
    disabled: boolean;
    defaultChecked: boolean;
    checked: boolean;
    className: string;
    onChange: (v: boolean) => void;
    icons: {checked?: React.ReactNode, unchecked?: React.ReactNode};
    labelClassName?: string;
    label?: string;
}

function SwitchButton(props: SwitchButtonProps) {
    const { defaultChecked, onChange, disabled, className, checked } = props;

    useEffect(() => {
        if (defaultChecked) {
            // setToggle(defaultChecked)
        }
    }, [defaultChecked]);

    const triggerToggle = () => {
        if (disabled) {
            return;
        }

        // setToggle(!toggle);

        if (typeof onChange === 'function') {
            onChange(!checked);
        }
    };

    const getIcon = (type: "checked" | "unchecked") => {
        const { icons } = props;
        if (!icons) {
            return null;
        }

        return icons[type] === undefined ?
            <></> :
            icons[type];
    };

    const toggleClasses = classNames('wrg-toggle', {
        'wrg-toggle--checked': props.checked,
        'wrg-toggle--disabled': disabled
    }, className);

    return (
        <div className={"flex flex-col"}>
            {props.label && <label className={clsx("block mb-2 text-sm font-medium leading-5 text-main-text", props.labelClassName)}>{props.label}</label>}
            <div onClick={triggerToggle} className={toggleClasses}>
                <div className="wrg-toggle-container">
                    <div className="wrg-toggle-check">
                        <span>{ getIcon('checked') }</span>
                    </div>
                    <div className="wrg-toggle-uncheck">
                        <span>{ getIcon('unchecked') }</span>
                    </div>
                </div>
                <div className="wrg-toggle-circle"></div>
                <input type="checkbox" aria-label="Toggle Button" className="wrg-toggle-input" />
            </div>
        </div>
    );
}

export { SwitchButton };