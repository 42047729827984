import clsx from "clsx";
import "./timeline.css";
import {BlinkingLink} from "../blinking/blinking-link";
import {faArrowLeft, faArrowRight, faWindowMaximize} from "@fortawesome/free-solid-svg-icons";
import BlinkingButton from "../blinking/blinking-button";
import RevealDiv from "../reveal/reveal";
import useWindowSize from "../../hooks/windowSize";

interface TimelineItemProps {
    data: TimelineItemData;
    orientation: "horizontal" | "vertical";
    key: number;
}

export interface TimelineItemData {
    children: React.ReactNode;
    className?: string;
    circleClassName?: string;
}

function TimelineItem(props: TimelineItemProps) {
    const { data } = props;
    return (
        <div className={clsx("timeline-item", `timeline-item-${props.orientation}`)}>
            <RevealDiv className={clsx("flex timeline-item-content", `timeline-item-content-${props.orientation}`, data.className)}>
                {props.data.children}
                <span className={clsx("circle", data.circleClassName)} />
            </RevealDiv>
        </div>
    );
}

interface TimelineProps {
    items: TimelineItemData[];
    className?: string;
    orientation?: "horizontal" | "vertical";
}

export default function Timeline(props: TimelineProps) {
    const { isMobile } = useWindowSize();

    if(!props.items.length) {
        return <></>;
    }

    if(!props.orientation) {
        props.orientation = "horizontal";
    }

    return (
        isMobile ? (
            <MobileTimeline items={props.items} className={props.className} orientation={props.orientation} />
        ) : (
            <BigScreenTimeline items={props.items} className={props.className} orientation={props.orientation} />
        )
    );
}

function MobileTimeline(props: TimelineProps) {
    return (
        <div className={clsx("flex flex-col", props.className)}>
            {props.items.slice().reverse().map((data, idx) => (
                <RevealDiv key={idx} className={clsx("flex timeline-item-content mb-4 text-left", data.className)}>
                    {data.children}
                    <span className={clsx("circle", data.circleClassName)} />
                </RevealDiv>
            ))}
        </div>
    );
}

function BigScreenTimeline(props: TimelineProps) {
    return (
        <div className={clsx("flex timeline-container", `timeline-container-${props.orientation} timeline`, props.className)}>
            {props.items.slice().reverse().map((data, idx) => (
                <TimelineItem
                    orientation={props.orientation ?? "horizontal"}
                    data={data}
                    key={idx}/>
            ))}
        </div>
    );
}