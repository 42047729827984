import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../button/icon-button";

interface SocialData {
    icon: IconDefinition;
    url: string;
    label: string;
}

export default function SocialView(props: SocialData) {
    return <IconButton icon={props.icon} onClick={() => window.open(props.url, "_blank")} label={props.label}/>;
}