import { Delta } from "quill";
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import React from "react";
import {useCreateArticle} from "../../../api/articleApi";
import TextEditor from "../../../widgets/text-editor/text-editor";
import {useNavigate} from "react-router-dom";
import {loadDraft, saveDraft} from "../../../widgets/text-editor/text-draft";
import useKey from "../../../hooks/keyPress";
import ArticleTopBar from "../article-top-bar/article-top-bar";
import ArticlePublish from "../article-publish/article-publish";

export default function ArticleWrite() {
    const [text, setText] = React.useState<Delta | null>(loadDraft());
    const [thumbnail, setThumbnail] = React.useState<File | null>(null);
    const [title, setTitle] = React.useState<string>("");
    const [description, setDescription] = React.useState<string>("");
    const [visible, setVisible] = React.useState<boolean>(true);
    const navigate = useNavigate();

    useKey("ctrls", (event) => {
        event.preventDefault();
        if(text) {
            saveDraft(text);
        }
    });

    const createArticle = useCreateArticle({
        onSuccess: (response: any) => {
            saveDraft(null);
            navigate(`/article/${response.data}`);
        },
        onError: (error: any) => {
            alert(error);
        },
    });

    return (
        <div className={"flex flex-col max-h-screen h-full w-full overflow-y-hidden p-1"}>
            <ArticleTopBar
                className={"md:max-h-46 md:h-46"}
                title={title}
                onTitleChange={setTitle}
                thumbnail={thumbnail}
                onThumbnailChange={setThumbnail}
                description={description}
                onDescriptionChange={setDescription}
                visible={visible}
                onVisibleChange={setVisible} />
            <TextEditor
                className={"flex-grow overflow-y-hidden p-2 h-full"}
                value={text}
                onChange={setText} />
            <ArticlePublish onPublish={() => createArticle.mutate({
                id: 0,
                content: JSON.stringify(text),
                title,
                thumbnail: thumbnail ?? undefined,
                description,
                publishDate: new Date(),
                visible
            })} className={"h-1/12 p-4"} />
        </div>
    );
}

/*
* <SplitView
                className={"flex-grow overflow-y-hidden"}
                left={<TextEditor className={"p-2 h-full"} value={text} onChange={setText} />}
                right={<TextDisplayer className={"p-2 h-full"} value={text} />}/>
                * */