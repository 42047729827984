import React from "react";
import {useArticle} from "../../../api/articleApi";
import {useParams} from "react-router-dom";
import TextDisplayer from "../../../widgets/text-editor/text-displayer";
import Loading from "../../../widgets/loading/loading";
import {Article} from "../../../model/article";
import {toDdMmYyyy} from "../../../helpers/dates";
import "./article-read.css";
import useAuthentication from "../../../hooks/authenticator";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {BlinkingLink} from "../../../widgets/blinking/blinking-link";
import {MetaTags} from "../../../widgets/meta/meta-tags";
import {apiConfig} from "../../../api/apiConfig";

export default function ArticleRead() {
    const params = useParams();
    const { data: article, isLoading, isError, isSuccess } = useArticle(parseInt(params.id ?? ""));

    return (
        <>
            {isLoading && <Loading className="force-margin-center" />}
            {isError && <div className={"text-alert"}>Error</div>}
            {isSuccess && <ArticleDisplay article={article.data}/>}
        </>
    );
}

interface ArticleDisplayProps {
    article: Article;
}

function ArticleDisplay(props: ArticleDisplayProps) {
    const { sessionData } = useAuthentication();
    const isAdmin = sessionData?.role === "Admin";

    const metaTags = {
        title: `Benjamin Wirtz - ${props.article.title}`,
        description: props.article.description,
        url: "https://benjamin-wirtz.com",
        image: `${apiConfig.url}/articles/images/${props.article.thumbnail}`,
    };

    return (
        <>
            <MetaTags title={metaTags.title} description={metaTags.description} url={metaTags.url} image={metaTags.image}/>
            <div className={"flex flex-col p-8 w-full overflow-y-auto"}>
                <div className={"mx-0 md:mx-20"}>
                    <div className={"md:max-w-screen-md mx-auto"}>
                        {isAdmin && (
                            <span className={"flex flex-col md:flex-row"}>
                                <BlinkingLink route={`/article-edit/${props.article.id}`} className={"ml-auto"} icon={faEdit}>Edit</BlinkingLink>
                            </span>
                        )}
                        <span className={"flex flex-col mx-auto"}>
                            <h1 className={"text-5xl font-bold text-center mr-auto w-full flex"}>{props.article.title}</h1>
                            <span className={"flex flex-col md:flex-row mr-auto w-full"}>
                                <p className={"mr-auto my-auto"}>{props.article.description}</p>
                                <span className={"flex flex-row w-full md:flex-col md:mr-0 font-bold opacity-30 mr-auto md:ml-auto"}>
                                    <p className={"md:ml-auto"}>{props.article.viewCount ?? 0} view{(props.article.viewCount ?? 0) === 1 ? "" : "s"}</p>
                                    <p className={"ml-auto"}>{`${toDdMmYyyy(new Date(props.article.publishDate))}`}</p>
                                </span>
                            </span>
                        </span>
                        <TextDisplayer value={JSON.parse(props.article.content)} className={"mt-10"}/>
                    </div>
                </div>
            </div>
        </>
    );
}