import clsx from "clsx";

interface TextInputData {
    name: string,
    value: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    className?: string,
    labelClassName?: string,
    placeholder?: string,
    type?: string,
    required?: boolean,
    disabled?: boolean,
    maxLength?: number,
}

export default function TextInput(props: TextInputData) {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        if (props.maxLength &&
            event.target.value.length > props.maxLength) {
            return;
        }

        props.onChange(event);
    };

    return (
        <div className={"flex flex-col"}>
            <label className={clsx("block text-sm font-medium leading-5 text-main-text", props.labelClassName)}>{props.name}</label>
            <input
                name={props.name}
                value={props.value}
                onChange={onChange}
                placeholder={props.placeholder}
                type={props.type ?? "text"}
                required={props.required ?? false}
                disabled={props.disabled ?? false}
                className={clsx("border border-t-transparent border-x-transparent border-b border-solid border-b-main-text bg-dark-background text-main-text tracking-widest transition duration-500 hover:text-accent hover:border hover:border-solid hover:border-accent", props.className)} />
        </div>
    );
}