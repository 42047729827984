import React, {useEffect} from "react";
import "./App.css";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/home/home";
import ArticleWrite from "./pages/articles/write/article-write";
import {QueryClient, QueryClientProvider} from "react-query";
import ArticleRead from "./pages/articles/read/article-read";
import ArticleEdit from "./pages/articles/edit/article-edit";
import {MetaTags} from "./widgets/meta/meta-tags";
import Header from "./widgets/header/header";

const queryClient = new QueryClient();

function App() {
    const [touchX, setTouchX] = React.useState(0);
    const [swipe, setSwipe] = React.useState(false);
    const metaTags = {
        title: `Benjamin Wirtz`,
        description: "My personal website: I'm a software engineer, check me out!",
        url: "https://benjamin-wirtz.com",
        image: "https://benjamin-wirtz.com/picture.bmp",
    }

    useEffect(() => {
        if(swipe)
            setSwipe(false);
    }, [swipe]);

    return (
        <QueryClientProvider client={queryClient}>
            <MetaTags title={metaTags.title} description={metaTags.description} url={metaTags.url} image={metaTags.image}/>
            <main
                className={"flex flex-fill h-screen bg-main-background text-main-text"}
                onTouchStart={e => setTouchX(e.touches[0].clientX)}
                onTouchEnd={e => {
                    if (touchX - e.touches[0].clientX > 20) {
                        setSwipe(true);
                    }
                }}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Header toSwipe={swipe}/>}>
                            <Route path="" element={<Home/>}/>
                            <Route path="/article-write" element={<ArticleWrite/>}/>
                            <Route path="/article/:id" element={<ArticleRead />}/>
                            <Route path="/article-edit/:id" element={<ArticleEdit/>}/>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </main>
        </QueryClientProvider>
    );
}

export default App;
