import hljs from "../../hljs/hljs";
import {Delta} from "quill";
import {Quill} from "react-quill";
import {useEffect, useState} from "react";
import "./text-displayer.css";
import clsx from "clsx";

interface TextDisplayerProps {
    value: Delta | null;
    className: string | undefined
}

export default function TextDisplayer(props: TextDisplayerProps) {
    const [html, setHtml] = useState<string>("");

    useEffect(() => {
        if(props.value != null) {
            const tempCont = document.createElement("div");
            const quill = new Quill(tempCont);
            quill.setContents(props.value);
            setHtml(quill.root.innerHTML);
        }
    }, [props.value]);

    useEffect(() => {
        document.
            querySelectorAll("pre").
            forEach((block) => {
                hljs.highlightBlock(block as HTMLElement);
            });
    }, [html]);

    if(!props.value) {
        return <span/>;
    }

    // overflow-y-auto
    return <div
        className={clsx("text-displayer h-full max-h-full flex-wrap", props.className)}
        dangerouslySetInnerHTML={{__html: html}} />;
}