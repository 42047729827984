import clsx from "clsx";
import TextInput from "../../../widgets/text-input/text-input";
import ImageUpload from "../../../widgets/file-upload/image-upload";
import React from "react";
import {SwitchButton} from "../../../widgets/switch-button/switch-button";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {faInvision} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface ArticleTopBarProps {
    className: string;
    title: string;
    onTitleChange: (title: string) => void;
    description: string;
    onDescriptionChange: (title: string) => void;
    thumbnail: File | null;
    onThumbnailChange: (thumbnail: File) => void;
    visible: boolean;
    onVisibleChange: (visible: boolean) => void;
}

export default function ArticleTopBar(props: ArticleTopBarProps) {
    return (
        <div className={clsx("flex flex-col md:flex-row p-4 border-b border-solid border-dark-background", props.className)}>
            <div className={"flex flex-col"}>
                <TextInput
                    maxLength={96}
                    labelClassName={"md:mb-2"}
                    placeholder={"Title"}
                    className={"flex flex-col md:p-2"}
                    name={"Title"}
                    value={props.title}
                    onChange={(evt) => props.onTitleChange(evt.target.value)} />
                <TextInput
                    maxLength={256}
                    labelClassName={"md:mb-2"}
                    placeholder={"Description"}
                    className={"flex flex-col md:p-2"}
                    name={"Description"}
                    value={props.description}
                    onChange={(evt) => props.onDescriptionChange(evt.target.value)} />
                <SwitchButton
                    label={"Public"}
                    className={"flex flex-col md:p-2"}
                    icons={{
                        checked: <FontAwesomeIcon icon={faEye} />,
                        unchecked: <FontAwesomeIcon icon={faEyeSlash} />
                    }}
                    disabled={false}
                    defaultChecked={true}
                    checked={props.visible}
                    onChange={(checked) => props.onVisibleChange(checked)}
                />
            </div>
            <ImageUpload
                label={"Thumbnail"}
                maxSize={4096}
                className={"md:ml-8 mr-auto md:mb-auto h-full"}
                onDropCallback={props.onThumbnailChange} />

        </div>);
}