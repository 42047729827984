import React from "react";
import "./changing-text.css";

interface ChangingTextProps {
    texts: string[];
    idleTime: number;
    typeTime: number;
}

interface ChangingTextState {
    len: number;
    currentText: string;
    showText: string;
    texts: string[];
    idleTime: number;
    typeTime: number;
}

export default class ChangingText extends React.Component<ChangingTextProps> {
    static invisibleChar = "\u200D";

    private ref: React.RefObject<HTMLSpanElement>;
    public state: ChangingTextState;


    constructor(props: ChangingTextProps) {
        super(props);
        
        const longer = props.texts.reduce((a, b) => a.length > b.length ? a : b);
        this.state = {
            len: 0,
            currentText: longer,
            showText: longer,
            texts: props.texts,
            idleTime: props.idleTime,
            typeTime: props.typeTime
        };
        this.ref = React.createRef();
    }

    _changeText() {
        const oldText = this.state.currentText;
        let newText = this.state.currentText;
        while(oldText === newText && this.state.texts.length > 1) {
            newText = this._randomText;
        }
        this.setState({
            currentText: newText,
            len: 0
        });
    }

    componentDidMount() {
        if(this.ref.current) {
            const width = this.ref.current.clientWidth * 1.1;
            this.ref.current.style.width = `${width}px`;
            this._changeText();
            this._erase();
        }
    }

    _erase() {
        const interval = setInterval(() => {
            let text = this.state.showText;
            if(this.state.len > 0) {
                const length = this.state.len - 1;
                this.setState({
                    showText: length === 0 ? ChangingText.invisibleChar : text.substring(0, length),
                    len: length
                });
            }
            else {
                this.setState({
                    showText: ChangingText.invisibleChar
                });
                clearInterval(interval);
                setTimeout(() => {
                    this._writeText();
                }, this.state.idleTime);
            }

        }, this.state.typeTime);
    }

    _writeText() {
        this._changeText();

        const interval = setInterval(() => {
            let text = this.state.currentText;
            if(this.state.currentText.length > this.state.len) {
                const length = this.state.len + 1;
                this.setState({
                    len: length,
                    showText: length === 0 ? ChangingText.invisibleChar : text.substr(0, length)
                });
            }
            else {
                clearInterval(interval);
                setTimeout(() => {
                    this._erase();
                }, this.state.idleTime);
            }
        }, this.state.typeTime);
    }

    get _randomText() {
        return this.state.texts[Math.floor(Math.random() * this.state.texts.length)];
    }

    render() {
        return <span className="inline-block text-main-background bg-main-text mr-4 changing-text" ref={this.ref}>{this.state.showText}</span>;
    }
}
