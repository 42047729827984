import React, {useEffect, useRef, useState} from "react";
import "./header.css";
import clsx from "clsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChessPawn, faDoorOpen, faFileEdit, faKey, faMoon, faPassport, faSun} from "@fortawesome/free-solid-svg-icons";
import useOutsideClicker from "../../hooks/outsideClicker";
import Modal from "../modal/modal";
import BlinkingButton from "../blinking/blinking-button";
import MyPGP from "../pgp/pgp";
import SocialView from "../social-view/social-view";
import {faGithub, faLinkedin, faPatreon} from "@fortawesome/free-brands-svg-icons";
import useAuthentication from "../../hooks/authenticator";
import LoginForm from "./login-form";
import IconButton from "../button/icon-button";
import {Outlet, useNavigate} from "react-router-dom";
import {SwitchButton} from "../switch-button/switch-button";
import useTheme from "../../hooks/theme";

export default function Header({toSwipe = false}: {toSwipe?: boolean}) {
    const [deployed, setDeployed] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showPgp, setShowPgp] = useState(false);
    const navRef = useRef(null);
    const { isLoggedIn, sessionData } = useAuthentication();
    const navigate = useNavigate();
    const {theme, setTheme} = useTheme();

    useOutsideClicker(navRef, () => setDeployed(false));

    const visibleStyle = deployed ? "data-visible" : "data-invisible";
    const shouldShowLogin = !isLoggedIn && showLogin;

    useEffect(() => {
        if(toSwipe) {
            setDeployed(true);
        }
    }, [toSwipe]);

    return (
        <>
            <div className={clsx("flex flex-col bg-dark-background p-4 min-h-screen", deployed ? "deployed" : "wrapped")} ref={navRef}>
                <span className={"flex flex-row"}>
                    <FontAwesomeIcon
                        className={"opacity-100 mr-auto cursor-pointer hover:text-accent text-2xl md:text-7xl"}
                        icon={faChessPawn}
                        onClick={() => setDeployed(!deployed)}
                        />
                    <div className={"flex flex-col"}>
                        <h1 onClick={() => navigate("/")} className={clsx("text-3xl my-auto ml-auto cursor-pointer", visibleStyle)}>Bedevin</h1>
                        {isLoggedIn ?
                            <SessionState className={visibleStyle} />
                            : <LoginButton className={visibleStyle} onClick={() => setShowLogin(true)} />}
                        <Modal noButtons title={"Login"} handleClose={() => setShowLogin(false)} show={shouldShowLogin} onClick={() => {}} size={"xs"}>
                            <LoginForm onLogged={() => setShowLogin(false)} />
                        </Modal>
                    </div>
                </span>
                <div className={clsx("border-main-text border-b p-4 opacity-50", visibleStyle)}/>
                <div className={clsx("flex flex-col p-4", visibleStyle)}>
                    <ProfilePic/>
                    <Name/>
                </div>
                <div className={clsx("border-main-text border-b p-4 opacity-50", visibleStyle)}/>
                <div className={clsx("flex flex-col p-4", visibleStyle)}>
                    <SwitchButton
                        disabled={false}
                        defaultChecked={true}
                        checked={theme === "light"}
                        className={"ml-auto"}
                        onChange={(v) => setTheme(v ? "light" : "dark")}
                        icons={{checked: <FontAwesomeIcon icon={faSun} className={"text-yellow-200"} />, unchecked: <FontAwesomeIcon icon={faMoon} />}}/>
                    <BlinkingButton className={"ml-auto"} onClick={() => setShowPgp(true)} icon={faKey}>
                        My PGP Key
                    </BlinkingButton>
                    <Modal title={"My PGP Key"} show={showPgp} handleClose={() => setShowPgp(false)} onClick={() => {
                    }} size={"s"}>
                        <MyPGP/>
                    </Modal>
                </div>
                <div className={clsx("border-main-text border-b p-4 opacity-50", visibleStyle)}/>
                <div className={clsx("flex flex-row justify-between p-4", visibleStyle)}>
                    {sessionData?.role === "Admin" && <IconButton onClick={() => navigate("/article-write")} label={"Write Article"} icon={faFileEdit}/>}
                </div>
                <div className={clsx("flex flex-col flex-grow", visibleStyle)}>

                </div>
                <div className={clsx("flex flex-row justify-between p-4", visibleStyle)}>
                    <SocialView url={"https://github.com/IncroyablePix"} label={"GitHub"} icon={faGithub}/>
                    <SocialView url={"https://www.linkedin.com/in/bfrwirtz/"} label={"LinkedIn"} icon={faLinkedin}/>
                    <SocialView url={"https://www.patreon.com/bedevin"} label={"Patreon"} icon={faPatreon}/>
                </div>
            </div>
            <Outlet/>
        </>
    );
}

interface SessionStateProps {
    className?: string;
}

function SessionState(props: SessionStateProps) {
    const { sessionData, logOut } = useAuthentication();

    return (
        <div className={clsx("flex flex-col text-xs", props.className)}>
            <p className={"ml-auto"}>Welcome {sessionData?.username}</p>
            <BlinkingButton icon={faDoorOpen} className={"ml-auto"} onClick={logOut}>Log out</BlinkingButton>
        </div>
    );
}

interface LoginButtonProps {
    onClick: () => void;
    className?: string;
}

function LoginButton(props: LoginButtonProps) {
    return (
        <BlinkingButton className={clsx("ml-auto text-xs", props.className)} onClick={props.onClick} icon={faPassport}>
            Login
        </BlinkingButton>
    );
}

function ProfilePic() {
    return <img className={"mx-auto rounded-full max-w-header-profile-pic"} src={`${process.env.PUBLIC_URL}/picture.bmp`} alt={"Me"}/>;
}

function Name() {
    return <span className={"mx-auto text-lg align"}>Benjamin Wirtz</span>;
}
