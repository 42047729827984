import React from "react";
import "./article-mini.css";
import FrameButton from "../button/frame-button";
import {useNavigate} from "react-router-dom";
import clsx from "clsx";

interface ArticleMiniData {
    thumbnail?: string;
    title: string;
    content: string;
    articleId: number;
    published: string;
    viewCount: number;
}

export default function ArticleMini(props: ArticleMiniData) {
    const navigate = useNavigate();

    return (
        <article key={props.articleId} className="group article-card">
            <div className="face face1 bg-main-background group-hover:bg-dark-background">
                <div className="contenu">
                    {props.thumbnail && (
                        <figure className="article_pic">
                            <img src={props.thumbnail} alt={`${props.title} thumbnail`} className="max-w-full object-contain"/>
                        </figure>)}
                    <h3 className={clsx("mb-2", !props.thumbnail && "text-4xl")}>{props.title}</h3>
                </div>
            </div>
            <div className="face face2 p-2 md:p-8 bg-main-background">
                <div className="contenu">
                    <span className={"flex flex-row text-xs md:text-xl"}>
                        <span>
                            {props.published}
                        </span>
                        <span className={"ml-auto opacity-30"}>
                            {props.viewCount} view{props.viewCount === 1 ? "" : "s"}
                        </span>
                    </span>
                    <p className={"break-words"}>
                        {props.content}
                    </p>
                    <FrameButton onClick={() => navigate(`/article/${props.articleId}`)} text={"Read Article"}></FrameButton>
                </div>
            </div>
        </article>
    );
}
/*<span className="text-lg">
                        {props.publish}
                    </span>*/