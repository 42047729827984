import BlinkingButton from "../blinking/blinking-button";
import {faWindowMaximize} from "@fortawesome/free-solid-svg-icons";
import "./place-card.css";

interface PlaceCardProps {
    date: string;
    text: string;
    description: string;
    link?: string;
    linkText?: string;
    className?: string;
    imageUrl?: string;
}

export default function PlaceCard(props: PlaceCardProps) {
    return (
        <div className={"flex flex-col w-full place-card h-full"}>
            <time className={"mb-0 place-card-time"}>{props.date}</time>
            <p className={"mt-0 p-0 place-card-title w-full"}>{props.text}</p>
            <p className={"flex-grow place-card-description w-full opacity-75"}>{props.description}</p>
            {props.link && (
                <BlinkingButton className={"ml-auto"} icon={faWindowMaximize} onClick={() => window.open(props.link, "_blank")}>
                    {props.linkText || props.link}
                </BlinkingButton>
            )}
        </div>
    );
}
