import clsx from "clsx";
import BlinkingButton from "../../../widgets/blinking/blinking-button";
import {faGalacticSenate} from "@fortawesome/free-brands-svg-icons";
import React from "react";

interface ArticlePublishProps {
    className: string;
    onPublish: () => void;
}

export default function ArticlePublish(props: ArticlePublishProps) {
    return (
        <div className={clsx("flex border-t-2 border-solid border-dark-background", props.className)}>
            <BlinkingButton onClick={props.onPublish} icon={faGalacticSenate} className={"p-2 ml-auto "}>Publish</BlinkingButton>
        </div>);
}