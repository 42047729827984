import BlinkingData from "./blinking-data";
import "./blinking.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useState} from "react";
import clsx from "clsx";

export default function BlinkingButton(props: BlinkingData) {
    const [isHover, setHover] = useState(false);

    return (
        <button className={clsx("text-main-text after:bg-main-text blinking-link nav-link", props.className)}
                type={props.type}
                onClick={props.onClick}
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
            <FontAwesomeIcon icon={props.icon} className={clsx("mt-auto", isHover ? "opacity-100" : "opacity-50")}/>
            <span className="ml-4"/>
            {props.title ?? props.children}
        </button>
    );
}
