import {axiosInstance, queryKeys} from "./apiConfig";
import {useMutation, UseMutationOptions, useQuery, useQueryClient} from "react-query";
import {AxiosResponse} from "axios";
import {Article} from "../model/article";

export function uploadArticleImage(file: File) {
    const formData = new FormData();
    formData.append("image", file);

    return axiosInstance.post("/articles/images", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export function uploadArticle(article: Article) {
    const formData = new FormData();
    formData.append("content", article.content);
    formData.append("title", article.title);
    formData.append("description", article.description);
    formData.append("visible", article.visible.toString());

    if (article.thumbnail) {
        formData.append("thumbnail", article.thumbnail);
    }

    return axiosInstance.post("/articles", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

export function editArticle(article: Article) {
    const formData = new FormData();
    formData.append("content", article.content);
    formData.append("title", article.title);
    formData.append("description", article.description);
    formData.append("visible", article.visible.toString());

    if (article.thumbnail) {
        formData.append("thumbnail", article.thumbnail);
    }

    return axiosInstance.put(`/articles/${article.id}`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

export function getAllArticles() {
    return axiosInstance.get("/articles");
}

export function getArticle(val: any) {
    const [, id] = val.queryKey;
    return axiosInstance.get(`/articles/${id}`);
}

// React Query
export function useCreateArticleImage(mutationOption: any) {
    const queryClient = useQueryClient();
    return useMutation((file: File) => uploadArticleImage(file), {
        ...mutationOption,
    });
}

export function useCreateArticle(mutationOption: any) {
    const queryClient = useQueryClient();
    return useMutation((article: Article) => uploadArticle(article), {
        ...mutationOption,
    });
}

export function useEditArticle(mutationOption: any) {
    const queryClient = useQueryClient();
    return useMutation((article: Article) => editArticle(article), {
        ...mutationOption,
    });
}

export function useArticle(id: number) {
    return useQuery([queryKeys.ARTICLES_GET_ONE, id], getArticle);
}

export function useAllArticles() {
    return useQuery(queryKeys.ARTICLES_GET_ALL, getAllArticles);
}