import {useEffect, useState} from "react";
import { useDropzone } from "react-dropzone";
import clsx from "clsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolder, faFolderOpen} from "@fortawesome/free-solid-svg-icons";

interface FileUploadProps {
    label: string;
    onDropCallback?: (files: File) => void;
    disabled?: boolean;
    className?: string;
    children?: React.ReactNode;
    maxSize: number;
}

export default function ImageUpload(props: FileUploadProps) {
    const [file, setFile] = useState<File | null>(null);
    const [isDisabled, setDisabled] = useState<boolean>(false);
    const [imageSource, setImageSource] = useState<string | ArrayBuffer | null>(null);

    const onDrop = (files: File[]) => {
        if(!isDisabled) {
            const [file] = files;

            if(file) {
                convertImageToBase64(file).
                then(base64 => {
                    setFile(file);
                    setImageSource(base64);
                    props.onDropCallback?.(file);
                }).
                catch(err => {

                });

            }
        }
    }

    useEffect(() => {
        setDisabled(props.disabled ?? false);
    }, [props.disabled]);

    const { getRootProps, getInputProps, isDragActive} = useDropzone({ onDrop });

    const convertImageToBase64 = (file: File) => {
        return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (error) => {
                reject(error);
            }
        });
    }

    return (
        <div className={clsx("flex flex-col items-center", props.className)} {...getRootProps()}>
            <input {...getInputProps()} name="file" disabled={isDisabled} accept={"image/x-png,image/gif,image/jpeg"} />
            <div className={"flex flex-col mb-auto mr-auto h-full"}>
                <label className={clsx("block text-sm font-medium leading-5 text-main-text")}>{props.label}</label>
                {
                    (file != null) ?
                        (<>
                            <span className={"text-primary h-full m-auto"}>
                                <img src={imageSource as string} className={"w-full max-h-full"} alt={"Article thumbnail"}/>
                            </span>
                        </>)
                        :
                        (isDragActive ?
                                (
                                    <span className={"m-auto ml-0 text-primary"}>
                                        <FontAwesomeIcon icon={faFolderOpen} className={"text-3xl md:text-8xl"} />
                                    </span>
                                )        :
                                (
                                    <span className="m-auto ml-0 text-primary">
                                        <FontAwesomeIcon icon={faFolder} className={"text-3xl md:text-8xl"} />
                                    </span>
                                )
                        )
                }
            </div>
        </div>
    )
}