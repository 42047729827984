import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar, faStarHalf} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useRef, useState} from "react";
import clsx from "clsx";

interface StarRatingProps {
    rating: number;
    onRatingChanges?: (rating: number) => void;
    disabled?: boolean;
    total?: number;
    className?: string;
}

export default function StarRating({ rating, onRatingChanges, disabled, total = 5, className }: StarRatingProps) {
    const starsRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [virtualRating, setVirtualRating] = useState(rating);

    const maxStars = Math.round(total);
    const fullStars = Math.min(Math.floor(virtualRating), maxStars);
    const halfStars = Math.round(virtualRating) - fullStars !== 0;

    useEffect(() => {
        if (starsRef.current && containerRef.current) {
            containerRef.current.style.width = `${starsRef.current.clientWidth}px`;
        }
    }, [starsRef, containerRef]);

    return (
        <div
            ref={containerRef}
            className={clsx("relative", className)}
            onMouseOver={(event) => {
                if(starsRef.current && !disabled) {
                    const width = starsRef.current.clientWidth;
                    const elementPos = starsRef.current.getBoundingClientRect();
                    const percentage = ((event.clientX - elementPos.left) / width);
                    setVirtualRating(Math.min(Number((Math.round(maxStars * 2 * percentage) / 2).toFixed(1)), maxStars));
                }
            }}
            onMouseLeave={() => {
                setVirtualRating(rating);
            }}
            onClick={() => {
                onRatingChanges?.(virtualRating);
            }
        }>
            {
                <>
                    <div ref={starsRef} className={"absolute flex flex-row"}>
                        {Array(maxStars).fill(0).map((_, index) => <FontAwesomeIcon className={"opacity-50"} icon={faStar}/>)}
                    </div>
                    <div className={"absolute z-10 flex flex-row"}>
                        {Array(fullStars).fill(0).map((_, index) => <FontAwesomeIcon icon={faStar}/>)}
                        {halfStars && <FontAwesomeIcon icon={faStarHalf}/>}
                    </div>
                </>
            }
        </div>
    );
}

// <FaStar color={idx < 3 ? "#01af93" : "#bbb"} />